import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import EMLogo from "../components/EMLogo"

class GetLoveThanksPage extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead", {
          content_name: "Love Notes",
          content_category: "Love Notes",
        })
      }
    }
  }

  render() {
    return (
      <Layout title="Empower + Rise: Thank you!" simpleFooter>
        <section className="black overflow-hidden relative">
          <div className="mt4 mb5 flex justify-around items-center w-100">
            <Link to="/">
              <EMLogo style={{ fill: "#dad1d2", width: "160px" }} />
            </Link>
          </div>

          <div className="mb5 w-90 f-headline-ns f2 center tc black">
            We love to love you.
          </div>
          <div className="brandon-light mv4 f4 tc black">
            <div className="center w-50-ns w-90">
              <div className="mb4 lh-copy">
                It’s true. We love to love you. Now that we are going to be pen
                pals, we will get to tell you how much you are loved each and
                every day. You very first daily love note is on the way to your
                inbox now.
              </div>
              <div className="mb4">
                Remember, if you ever decide you don’t want to get daily love
                all you have to do is unsubscribe.
              </div>
              <div className="mb4">Easy breezy.</div>
              <div className="mb4">We love you, we don’t spam you. ;)</div>
              <div className="mb5">Hehe. Seriously.</div>
              <div className="mb4">Love,</div>
            </div>

            <h1 className="xtreem-thin f1 mb6">Empower and Rise</h1>
          </div>
        </section>
      </Layout>
    )
  }
}

export default GetLoveThanksPage
